import { OpenInNew } from "@mui/icons-material";
import { styled, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";
import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface StyledTextLinkProps extends LinkProps {
	children: React.ReactNode;
	variant?: TypographyProps["variant"];
	showExternalLinkIcon?: boolean;
}

interface StyledTextAnchorProps {
	children: React.ReactNode;
	variant?: TypographyProps["variant"];
	href?: string;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
	showExternalLinkIcon?: boolean;
}

/**
 * Remove the default anchor tag styling on react-router "Link" component
 */
const StyledLink = styled(Link)({
	textDecoration: "none"
});

/**
 * Remove the default anchor tag styling
 */
const StyledAnchor = styled("a")({
	textDecoration: "none"
});

/**
 * Use the theme's primary color for the link color.
 * Change the color of the icon when hovering over the link to match the text color.
 */
const StyledTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.hover,
	display: "inline",
	"&:hover": {
		color: theme.palette.primary.main,
		"& svg": {
			color: theme.palette.primary.main
		}
	}
}));

/**
 * Use the theme's primary color for the external link icon and make its width and height 12px and add margins to its sides.
 */
const StyledOpenInNew = styled(OpenInNew)(({ theme }) => ({
	color: theme.palette.primary.hover,
	height: "12px",
	width: "12px",
	margin: "0 0.25rem 0"
}));

/**
 * A styled link that uses the theme's primary color for the link color.
 * Used anywhere developers want to use react router's "Link" component wrapped
 * around text, anchor tags, or any tool that has a default appearance to an
 * html link on a page.
 * @param param0
 * @returns
 */
const StyledTextLink = (props: StyledTextLinkProps | StyledTextAnchorProps) => {
	// checks if used for react-router, otherwise it's an anchor tag
	const isInternalLink = isStyledTextLinkProps(props);
	// React-Router uses the "to" prop, while anchor tags use the "href" prop
	if (isInternalLink) {
		const { to, ...rest } = props as StyledTextLinkProps;
		return (
			<StyledLink to={to} {...rest}>
				<StyledTypography variant={rest.variant} as="span">
					{rest.children}
					{rest.showExternalLinkIcon && <StyledOpenInNew />}
				</StyledTypography>
			</StyledLink>
		);
	} else {
		const { href, ...rest } = props as StyledTextAnchorProps;
		return (
			<StyledAnchor href={href} {...rest}>
				<StyledTypography variant={rest.variant} as="span">
					{rest.children}
					{rest.showExternalLinkIcon && <StyledOpenInNew />}
				</StyledTypography>
			</StyledAnchor>
		);
	}
};

/**
 * Type guard to check if the props are for a react-router
 * "Link" component, as identified by the "to" prop.
 * @param props
 * @returns
 */
function isStyledTextLinkProps(
	props: StyledTextLinkProps | StyledTextAnchorProps
): props is StyledTextLinkProps {
	return "to" in props;
}

export default StyledTextLink;
