import { Accordion, styled } from "@mui/material";

/**
 * Styled Accordion component that supports conditional styling
 * @component Material.Accordion
 * @property {'normal' | 'softGlow'} [styleVariant] - The style variant to apply to the accordion.
 */
export const AdaAccordion = styled(Accordion, {
	shouldForwardProp: (prop) => prop !== "styleVariant"
})<{ styleVariant?: "normal" | "softGlow" }>(({ styleVariant }) => {
	if (styleVariant === "softGlow") {
		return {
			border: "1px solid rgba(0, 0, 0, 0.12)",
			borderRadius: "8px",
			boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
			marginBottom: 2,
			"&:before": {
				display: "none" // Remove default MUI divider line
			}
		};
	}
});
