import { Typography, TypographyProps } from "@mui/material";
import * as React from "react";
import StyledTextLink from "../../components/StyledTextLink";
/**
 * A configuration object for a single info panel topic
 * Renders information as accordion or heading with content
 */
export interface InfoPanelSectionConfig {
	/**
	 * The title of the topic
	 * Anchor button uses this to open the section on click
	 */
	title: string;
	/**
	 * An optional display title, if the input label differs from the API definition, set this as the label
	 * This allows custom, user friendly display titles without interfering with opening through an anchor (looks at title)
	 * ex. displayTitle: "Plan Type", title: "Type"
	 */
	displayTitle?: string;
	/**
	 * Optional props to style the title
	 */
	titleProps?: TypographyProps;
	/**
	 * The content of the topic
	 * Is a React Node, so links can be embedded
	 * Content will always render above any nested children
	 */
	content?: React.ReactNode;
	/**
	 * Additional child sections to render
	 */
	nestedSections?: Array<InfoPanelSectionConfig>;
	/**
	 * Determines if content should be rendered as an accordion or non-expandable heading with content
	 */
	isAccordion: boolean;
}

/**
 * The configuration for an info panel
 */
export interface InfoPanelConfig {
	/**
	 * An array of sections to render
	 */
	sections: Array<InfoPanelSectionConfig>;
	/**
	 * An optional title for the panel
	 */
	title: string;
}

export const DefaultInfoPanelConfig: InfoPanelConfig = {
	sections: [
		{
			title: "About ADA Web",
			content: (
				<>
					<Typography>
						ADA Web facilitates policy creation and testing on the ADA application that
						resides on Stellantis vehicles.
						<br />
						Learn More:
					</Typography>
					<ul>
						<li>
							<StyledTextLink
								href="https://stla-swx-confluence.atlassian.net/wiki/spaces/CS/pages/184026188/ADA+Web"
								target="_blank"
								aria-label="go to confluence"
								showExternalLinkIcon
							>
								Confluence Documentation
							</StyledTextLink>
						</li>
						<li>
							<StyledTextLink
								href="/api-docs"
								target="_blank"
								aria-label="go to swagger api documentation"
							>
								API Documentation
							</StyledTextLink>
						</li>
					</ul>
				</>
			),
			isAccordion: true
		},
		{
			title: "ADA Core Concepts",
			content: (
				<>
					<ul>
						<li>
							<StyledTextLink
								href="https://stla-swx-confluence.atlassian.net/wiki/spaces/CS/pages/239178103/ADA+101"
								target="_blank"
								aria-label="go to ada 101 confluence page"
								showExternalLinkIcon
							>
								ADA 101
							</StyledTextLink>
						</li>
						<li>
							<StyledTextLink
								href="https://stla-swx-confluence.atlassian.net/wiki/spaces/CS/pages/164595381/ADA+Policy+and+Plan"
								target="_blank"
								aria-label="go to policies confluence page"
								showExternalLinkIcon
							>
								Policies and Plans
							</StyledTextLink>
						</li>
						<li>
							<StyledTextLink
								href="https://stla-swx-confluence.atlassian.net/wiki/spaces/CS/pages/163941958/ADA+Data+Element"
								target="_blank"
								aria-label="go to data elements confluence page"
								showExternalLinkIcon
							>
								Data Elements
							</StyledTextLink>
						</li>
						<li>
							<StyledTextLink
								href="https://stla-swx-confluence.atlassian.net/wiki/spaces/CS/pages/164529617/ADA+Vehicle+Cloud+API"
								target="_blank"
								aria-label="go to vehicle to cloud api confluence page"
								showExternalLinkIcon
							>
								Vehicle to Cloud API
							</StyledTextLink>
						</li>
					</ul>
				</>
			),
			isAccordion: false
		}
	],
	title: "ADA Home"
};
