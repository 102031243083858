import { AppBar, IconButton, keyframes, Paper, styled } from "@mui/material";
import { bottomNavHeight } from "../../themes";

/**
 * The width of the info panel that's always visible in rem
 */
export const persistentInfoPanelWidth = 2.5;

/**
 * The width of the expanded portion of the panel in rem
 */
const expandedInfoPanelWidth = 17.5;

/**
 * The total combined width when expanded
 */
export const totalInfoPanelWidth = persistentInfoPanelWidth + expandedInfoPanelWidth;

/**
 * The padding for expanded pane items
 */
export const defaultPadding = "0 1rem";

/**
 * The height of the info panel header
 */
const infoPanelHeaderHeight = "68.5px";

const glow = keyframes`
0% {
  box-shadow: 0 0 5px rgba(33, 150, 243, 0.3), 0 0 10px rgba(33, 150, 243, 0.3), 0 0 15px rgba(33, 150, 243, 0.3);
}
50% {
  box-shadow: 0 0 20px rgba(33, 150, 243, 0.5), 0 0 30px rgba(33, 150, 243, 0.5), 0 0 40px rgba(33, 150, 243, 0.5);
}
100% {
  box-shadow: 0 0 5px rgba(33, 150, 243, 0.3), 0 0 10px rgba(33, 150, 243, 0.3), 0 0 15px rgba(33, 150, 243, 0.3);
}
`;

// Styled component for the pulsating effect
export const GlowingIconButton = styled(IconButton)(({ theme }) => ({
	animation: `${glow} 1.5s infinite`
}));

// Style to add class to trigger pulsating effect
export const highlightClass = styled("style")`
	.highlight {
		animation: ${glow} 1.5s infinite;
	}
`;

/**
 * The entire info panel
 * @component Material.AppBar
 */
export const InfoPanel = styled(AppBar, { shouldForwardProp: (prop) => prop !== "isOpen" })<{
	isOpen: boolean;
}>(({ isOpen, theme }) => ({
	maxWidth: isOpen ? `${totalInfoPanelWidth}rem` : `${persistentInfoPanelWidth}rem`,
	height: "100vh",
	display: "flex",
	flexDirection: "row",
	// Adds bottom margin so content isn't hidden by bottom nav
	[theme.breakpoints.down("md")]: {
		paddingBottom: bottomNavHeight
	}
}));

/**
 * The persistent portion of the info panel
 * @component Material.Paper
 */
export const PersistentPanel = styled(Paper)({
	display: "flex",
	alignItems: "flex-start",
	width: `${persistentInfoPanelWidth}rem`
});

/**
 * The expanded portion of the panel
 * @component Material.Paper
 */
export const ExpandedPanel = styled(Paper)({
	flexGrow: 1,
	overflowY: "auto"
});

/**
 * The header of the expanded portion
 * @component Material.Paper
 */
export const Header = styled(Paper)({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	minHeight: infoPanelHeaderHeight,
	padding: defaultPadding,
	position: "sticky",
	top: 0,
	zIndex: 1 // elevates above content
});

/**
 * A wrapper for accordion sections
 * @component div
 */
export const AccordionWrapper = styled("div")({
	"& .MuiPaper-root": {
		backgroundColor: "transparent" // Overrides elevation 0 background color
	},
	"& .MuiAccordionSummary-content": {
		margin: ".5rem 0"
	},
	"& .MuiButtonBase-root": {
		minHeight: "fit-content"
	}
});

/**
 * A wrapper for info panel sections
 * Has a set scroll margin so that when scrolling to top it does not go behind the info panel header
 * @component div
 */
export const SectionWrapper = styled("div")({
	scrollMargin: infoPanelHeaderHeight
});
